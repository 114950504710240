import React from "react";
import ReactDOM from "react-dom/client";

import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import mixpanel from "mixpanel-browser";

import Root from "./routes/root";
import Questions from "./routes/questions";
import ErrorPage from "./routes/404";
import Congrats from "./routes/congrats";
import Leaderboard from "./routes/leaderboard";
import Result from "./routes/result";

import reportWebVitals from "./reportWebVitals";

import "./styles/globals.scss";

mixpanel.init(process.env.MIXPANEL_API!, {
  track_pageview: true,
  persistence: "localStorage",
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/questions",
    element: <Questions />,
  },
  {
    path: "/congrats/:id",
    element: <Congrats />,
  },
  {
    path: "/result/:id",
    element: <Result />,
  },
  {
    path: "/leaderboard",
    element: <Leaderboard />,
  },
]);

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <QueryClientProvider client={queryClient}>
    <RouterProvider router={router} />
  </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
