import axios from "axios";

export const fetchQuestions = async () => {
  const { data } = await axios.get("https://api.pickandrollgame.com/props");

  return data;
};

export const postAnswers = async (selectedOptions: SelectedOptions[]) => {
  const { data } = await axios.post("https://api.pickandrollgame.com/enter", {
    picks: selectedOptions,
  });

  return data;
};

export const postEmail = async (emailData: EmailData) => {
  const { data } = await axios.post("https://api.pickandrollgame.com/email", {
    ...emailData,
  });

  return data;
};

export const fetchLeaderboard = async () => {
  const { data } = await axios.get("https://api.pickandrollgame.com/leaders");

  return data;
};

export const fetchTeamData = async (display_name: string) => {
  const { data } = await axios.get(
    `https://api.pickandrollgame.com/team/${display_name}`
  );

  return data;
};
