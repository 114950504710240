import { useQuery } from "@tanstack/react-query";
import { RotatingLines } from "react-loader-spinner";
import { Link } from "react-router-dom";

import { fetchLeaderboard, fetchQuestions } from "../../utils/apis";

import styles from "./leaderboard.module.scss";

import logo from "../../assets/logos/logo.png";
import crownImg from "../../assets/result/crown.png";
import { ReactComponent as Beams } from "../../assets/congrats/beams.svg";

const goalDate = new Date("2024-02-18T20:00:00-05:00");
const didMatchStart = goalDate.getTime() - new Date().getTime() <= 0;

const Leaderboard: React.FC = () => {
  const { data, isLoading } = useQuery<LeaderboardsData, Error>({
    queryKey: ["leaderboard"],
    queryFn: fetchLeaderboard,
  });
  const { data: questionsData, isLoading: isQuestionsLoading } = useQuery<
    QuestionsData,
    Error
  >({
    queryKey: ["questions"],
    queryFn: fetchQuestions,
  });

  return (
    <div className={styles.container}>
      <Beams className={styles.beams} />
      <Link to="/">
        <img src={logo} alt="Logo" className={styles.logo} />
      </Link>
      <img src={crownImg} alt="Crown" className={styles.crown} />
      <h1>LEADERBOARD</h1>
      {isLoading ? (
        <div className={styles.loading}>
          <RotatingLines
            strokeColor="rgb(255, 255, 255)"
            strokeWidth="4"
            ariaLabel="rotating-lines-loading"
            width="80"
          />
        </div>
      ) : (
        <>
          <h2>Total Entries: {data?.total}</h2>
          <div className={styles.column}>
            {data?.leaders?.map((item, index) => (
              <div className={styles.boxLong} key={item.display_name}>
                <div className={`${styles.inner} ${styles.row}`}>
                  <div className={styles.left}>
                    <h3 className={styles.rank}>{++index}</h3>

                    <h3 className={styles.name}>
                      <Link to={`/result/${item.display_name}`}>
                        {item.display_name}
                      </Link>
                    </h3>
                  </div>
                  <div
                    className={styles.right}
                    style={{
                      backgroundColor: item.score > 5 ? "#FDC501" : "#E3E3E3",
                      color: item.score > 5 ? "#000000" : "#878787",
                    }}
                  >
                    {didMatchStart ? item.score : "-"}/
                    {questionsData?.props?.length || 0}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Leaderboard;
