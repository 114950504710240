import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { RotatingLines } from "react-loader-spinner";

import { postEmail } from "../../utils/apis";
import { Mixpanel } from "../../utils/mixpanel";

import styles from "./congrats.module.scss";

import logo from "../../assets/logos/logo.png";
import linkBox from "../../assets/congrats/linkBox.png";
import shareBox from "../../assets/congrats/shareBox.png";
import star from "../../assets/congrats/star.png";
import { ReactComponent as Button1 } from "../../assets/buttons/button1.svg";
import { ReactComponent as Check } from "../../assets/congrats/check.svg";
import { ReactComponent as Button } from "../../assets/congrats/button.svg";
import { ReactComponent as ButtonDisabled } from "../../assets/congrats/buttonDisabled.svg";
import { ReactComponent as Beams } from "../../assets/congrats/beams.svg";

const goalDate = new Date("2024-02-18T20:00:00-05:00");

const calculateTimeLeft = () => {
  const now = new Date();
  const timeDifference = goalDate.getTime() - now.getTime();

  if (timeDifference <= 0) {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }

  const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

  return {
    days,
    hours,
    minutes,
    seconds,
  } as Counter;
};

const Congrats: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [timeLeft, setTimeLeft] = useState<Counter>(calculateTimeLeft());

  const { state, pathname } = useLocation();
  const navigate = useNavigate();

  const path = pathname.split("/congrats/")[1];

  const link = `pickandrollgame.com/result/${path}`;

  useEffect(() => {
    const intervalId = setInterval(() => {
      const timeLeftNew: Counter = calculateTimeLeft();
      setTimeLeft(timeLeftNew);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const checkSubmittedStatus = () => {
    const item = localStorage.getItem(path);
    if (item) {
      setIsSubmitted(true);
    }
  };

  useEffect(() => {
    checkSubmittedStatus();
  }, []);

  const onShareClickHandler = async () => {
    try {
      await navigator.clipboard.writeText(link);

      Mixpanel.track("Share", {
        "Link copied": {
          url: link,
        },
      });
    } catch (error) {
      console.error("Error sharing:", error);
    }
  };

  const onSubmitClickHandler = async () => {
    if (isLoading) return;

    if (!state?.response?.token) {
      setError("Something went wrong.");
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email || !emailRegex.test(email || "")) {
      setError("Please enter a valid email address.");
      return;
    }

    setError(null);
    setIsLoading(true);

    try {
      await postEmail({
        display_name: path,
        email,
        token: state.response.token,
      });

      Mixpanel.track("Email submitted", {
        "Email submitted": {
          display_name: path,
          email,
        },
      });

      setIsSubmitted(true);
      localStorage.setItem(
        path,
        JSON.stringify({
          email,
        })
      );
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const renderTimeLeft = () => {
    let timeString = "";
    if (timeLeft.days > 0) {
      timeString +=
        timeLeft.days + " DAY" + (timeLeft.days > 1 ? "S - " : " - ");
    }
    timeString += (timeLeft.hours < 10 ? "0" : "") + timeLeft.hours + ":";
    timeString += (timeLeft.minutes < 10 ? "0" : "") + timeLeft.minutes + ":";
    timeString += (timeLeft.seconds < 10 ? "0" : "") + timeLeft.seconds;

    return timeString;
  };

  const onContinuePressHandler = () => {
    const now = new Date();
    const timeDifference = goalDate.getTime() - now.getTime();

    if (timeDifference > 0) {
      return;
    }

    navigate(`/leaderboard?display_name=${path}`);
  };

  return (
    <div className={styles.container}>
      <Beams className={styles.beams} />
      <Link to="/">
        <img src={logo} alt="Logo" className={styles.logo} />
      </Link>
      <div className={styles.starBox}>
        <img src={star} alt="" className={styles.star} />
      </div>
      <div className={styles.text}>
        <h1>CONGRATS,</h1>
        <h1>{path?.toUpperCase()}</h1>
      </div>
      <h3 className={styles.congrats}>
        You're done and your answers have been recorded!
      </h3>
      <div className={styles.row}>
        <div className={styles.linkBox}>
          <img src={linkBox} alt="" draggable="false" />
          <Link to={`/result/${path}`}>
            <h3>{link}</h3>
          </Link>
        </div>
        <div className={styles.share} onClick={onShareClickHandler}>
          <img
            src={shareBox}
            alt="Share button"
            className={styles.share}
            draggable="false"
          />
        </div>
      </div>
      <div className={styles.box}>
        <div className={styles.inner}>
          <h4>Enter your email address to receive a copy of your results</h4>
          {isLoading ? (
            <div className={styles.loading}>
              <RotatingLines
                strokeColor="rgb(0, 0, 0)"
                strokeWidth="4"
                ariaLabel="rotating-lines-loading"
                width="80"
              />
            </div>
          ) : !isSubmitted ? (
            <>
              <input
                placeholder="Enter your email"
                type="email"
                className={styles.input}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {error ? <h3 className={styles.error}>{error}</h3> : null}
              <div className={styles.button} onClick={onSubmitClickHandler}>
                <Button1 />
                <h3>SUBMIT</h3>
              </div>
            </>
          ) : (
            <div className={styles.submittedBox}>
              <Check />
              <h4>Thank you!</h4>
              <h4>Your email has been recorded</h4>
            </div>
          )}
        </div>
      </div>
      <div className={styles.finalButton} onClick={onContinuePressHandler}>
        {goalDate.getTime() - new Date().getTime() <= 0 ? (
          <>
            <Button />
            <div>
              <h3>CONTINUE</h3>
            </div>
          </>
        ) : (
          <>
            <ButtonDisabled />
            <div>
              <h4>GAME STARTS IN</h4>
              <h4>{renderTimeLeft()}</h4>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Congrats;
