import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { useQuery } from "@tanstack/react-query";
import { RotatingLines } from "react-loader-spinner";

import { fetchQuestions, fetchTeamData } from "../../utils/apis";

import styles from "./result.module.scss";

import logo from "../../assets/logos/logo.png";
import flagImg from "../../assets/result/flag.png";
import crownImg from "../../assets/result/crown.png";
import checkImg from "../../assets/result/check.png";
import xImg from "../../assets/result/x.png";
import leaderboard from "../../assets/result/leaderboard.png";
import { ReactComponent as Beams } from "../../assets/congrats/beams.svg";

const goalDate = new Date("2024-02-18T20:00:00-05:00");

const calculateTimeLeft = () => {
  const now = new Date();
  const timeDifference = goalDate.getTime() - now.getTime();

  if (timeDifference <= 0) {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }

  const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

  return {
    days,
    hours,
    minutes,
    seconds,
  } as Counter;
};

interface ItemProps {
  item: QuestionData;
}

const PickItem: React.FC<ItemProps> = ({ item }) => {
  return (
    <div className={styles.itemRow}>
      <img
        src={
          item.outcome !== undefined
            ? item.outcome
              ? checkImg
              : xImg
            : checkImg
        }
        alt=""
      />
      <div className={styles.column}>
        <p>{item.description}</p>
        {item.choice?.map((item) => (
          <p key={item}>{item}</p>
        ))}
      </div>
    </div>
  );
};

const Result: React.FC = () => {
  const [timeLeft, setTimeLeft] = useState<Counter>(calculateTimeLeft());

  const { pathname } = useLocation();

  const path = pathname.split("/result/")[1];

  const { data, isLoading } = useQuery<TeamData, Error>({
    queryKey: [`team_${path}`],
    queryFn: () => fetchTeamData(path),
  });
  const { data: questionsData, isLoading: isQuestionsLoading } = useQuery<
    QuestionsData,
    Error
  >({
    queryKey: ["questions"],
    queryFn: fetchQuestions,
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      const timeLeftNew: Counter = calculateTimeLeft();
      setTimeLeft(timeLeftNew);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const renderTimeLeft = () => {
    let timeString = "";
    if (timeLeft.days > 0) {
      timeString +=
        timeLeft.days + " Day" + (timeLeft.days > 1 ? "s - " : " - ");
    }
    timeString += (timeLeft.hours < 10 ? "0" : "") + timeLeft.hours + ":";
    timeString += (timeLeft.minutes < 10 ? "0" : "") + timeLeft.minutes + ":";
    timeString += (timeLeft.seconds < 10 ? "0" : "") + timeLeft.seconds;

    return timeString;
  };

  return (
    <div className={styles.container}>
      <Beams className={styles.beams} />
      <Link to="/">
        <img src={logo} alt="Logo" className={styles.logo} />
      </Link>
      <div className={styles.box}>
        <div className={styles.inner}>
          <h4>TOTAL SCORE</h4>
          <h1>
            {data?.result || "-"}
            <span>/</span>
            {questionsData?.props?.length || 0}
          </h1>
          {goalDate.getTime() - new Date().getTime() <= 0 ? (
            <h5></h5>
          ) : (
            <h5>Game Starts In: {renderTimeLeft()}</h5>
          )}
        </div>
      </div>
      {isLoading ? (
        <div className={styles.loading}>
          <RotatingLines
            strokeColor="rgb(255, 255, 255)"
            strokeWidth="4"
            ariaLabel="rotating-lines-loading"
            width="80"
          />
        </div>
      ) : data?.picks?.length ? (
        <>
          <div className={styles.yourPicks}>
            <img alt="Flag" src={flagImg} />
            <h4>YOUR PICKS</h4>
          </div>
          <div className={styles.boxLong}>
            <div className={styles.inner}>
              {data.picks?.map((item) => (
                <PickItem item={item} key={item.prop_id} />
              ))}
            </div>
          </div>
        </>
      ) : null}
      <div className={styles.yourPicks}>
        <img alt="Crown" className={styles.crown} src={crownImg} />
        <h4>YOUR RANK</h4>
      </div>
      <div className={styles.boxLong}>
        <div className={`${styles.inner} ${styles.row}`}>
          <div className={styles.left}>
            <h3 className={styles.rank}>{data?.rank}</h3>
            <h3 className={styles.name}>{path} (You)</h3>
          </div>
          <div className={styles.right}>
            {data?.result || "-"}/{questionsData?.props?.length || 0}
          </div>
        </div>
      </div>
      <Link to="/leaderboard">
        <img alt="" className={styles.leaderboard} src={leaderboard} />
      </Link>
    </div>
  );
};

export default Result;
