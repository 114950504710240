export const questions: NBAQuestion[] = [
  {
    prop_id: "mvp",
    image: "https://nbaallstarweekend.com/img/mvp.png",
    choices: [
      "LeBron James",
      "Giannis Antetokounmpo",
      "Stephen Curry",
      "Nikola Jokic",
    ],
    order: 0,
    description: "Who will be awarded the NBA All-Star Game MVP?",
    type: "single",
    bonus:
      "The last player to win back-to-back All-Star Game MVPs was LeBron James in 2008 and 2009.",
  },
  {
    prop_id: "3point",
    image: "https://nbaallstarweekend.com/img/3point.png",
    choices: [
      "Devin Booker",
      "Klay Thompson",
      "Damian Lillard",
      "Nikola Jokic",
    ],
    order: 1,
    description: "Who will win the NBA All-Star Three-Point Contest?",
    type: "single",
    bonus:
      "The record for the most points scored in the Three-Point Contest is 27, held by Stephen Curry.",
  },
  {
    prop_id: "dunk",
    image: "https://nbaallstarweekend.com/img/dunk.png",
    choices: [
      "Zion Williamson",
      "Ja Morant",
      "Anthony Edwards",
      "Nikola Jokic",
    ],
    order: 2,
    description:
      "Which player will have the highest score in the NBA All-Star Dunk Contest?",
    type: "multiple",
    bonus:
      "The first dunk in NBA Slam Dunk Contest history was performed by Larry Nance in 1984.",
  },
  {
    prop_id: "skills",
    image: "https://nbaallstarweekend.com/img/skills.png",
    choices: ["Luka Doncic", "Chris Paul", "Dejounte Murray", "Nikola Jokic"],
    order: 3,
    description: "Who will win the NBA All-Star Skills Challenge?",
    type: "multiple",
    bonus:
      "The NBA Skills Challenge was introduced during the 2002 NBA All-Star Weekend.",
  },
  {
    prop_id: "risingstars",
    image: "https://nbaallstarweekend.com/img/risingstars.png",
    choices: ["Team USA", "Team World", "Nikola Jokic", "Random Player"],
    order: 4,
    description:
      "Arrange the teams in the order of total points scored in the NBA Rising Stars Challenge.",
    type: "dnd",
    bonus:
      "The Rising Stars Challenge features the league's best rookies and sophomores showcasing their talents.",
  },
  {
    prop_id: "celebgame",
    image: "https://nbaallstarweekend.com/img/celebgame.png",
    choices: [
      "Team Lakers",
      "Team Clippers",
      "Nikola Jokic",
      "Random Celebrity",
    ],
    order: 5,
    description:
      "Arrange the celebrity teams in the order of total points scored in the NBA Celebrity Game.",
    type: "dnd",
    bonus:
      "The NBA Celebrity Game is known for its mix of entertainment and basketball, featuring celebrities and former NBA players.",
  },
];
