export const sleep = (ms: number): Promise<void> =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const renderSingleChoice = (inputString: string): string => {
  const upperCaseString = inputString.toUpperCase();

  if (upperCaseString.includes("OVER") || upperCaseString.includes("UNDER")) {
    return inputString.replace(/(OVER|UNDER)/i, "$1\n").toUpperCase();
  }

  if (upperCaseString.includes("/") && !upperCaseString.includes(" / ")) {
    return inputString.split("/").join(" / ").toUpperCase();
  }

  return inputString.toUpperCase();
};

export const reorder = (
  list: string[],
  startIndex: number,
  endIndex: number
) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
