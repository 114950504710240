import { useRef, useState } from "react";

import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
  ResponderProvided,
} from "react-beautiful-dnd";
import TinderCard from "react-tinder-card";

import { renderSingleChoice, reorder } from "../../utils/helpers";

import styles from "./card.module.scss";

import buttonSelected from "../../assets/button-selected.png";
import buttonUnselected from "../../assets/button-unselected.png";
import doneDisabled from "../../assets/done-disabled.png";
import doneEnabled from "../../assets/done-enabled.png";
import dragImg from "../../assets/dots-vertical.png";
import { ReactComponent as SelectButton } from "../../assets/selectButton.svg";

interface Props {
  item: QuestionData;
  onClick: (prop_id: string, choice: number[]) => void;
  zIndex: number;
  buttonDisabled: boolean;
  setButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  currentQuestion: number;
  index: number;
}

interface ContainerProps {
  children: React.ReactNode | React.ReactNode[];
}

const Card: React.FC<Props> = ({
  item,
  onClick,
  zIndex,
  buttonDisabled,
  setButtonDisabled,
  currentQuestion,
  index,
}) => {
  const [isShown, setIsShown] = useState<boolean>(true);
  const [selectedData, setSelectedData] = useState<number[]>(
    item.multi && item.ranked ? item.choices.map((_val, ind) => ind) : []
  );
  const [data, setData] = useState(item.choices);

  const onSwipeCompleted = (direction: Direction) => {
    onItemClick(direction === "left" ? 0 : 1);
  };

  const onItemClick = async (singleItem: number) => {
    // setIsShown(false);
    if (!item.multi && !item.ranked) {
      setSelectedData([singleItem]);
      if (item.choices.length === 2) {
        setIsShown(false);
        onClick(item.prop_id, [singleItem]);
      }
    }

    if (item.multi && !item.ranked) {
      //Multi-choice question without sorting
      let temp = [...selectedData];
      if (selectedData.includes(singleItem)) {
        temp = temp.filter((i) => i !== singleItem);
      } else {
        temp.push(singleItem);
      }
      setSelectedData(temp);
    }
  };

  const cardRef = useRef<TinderAPI>(null);

  const handleButtonClick = (direction: "left" | "right") => {
    if (buttonDisabled) return;
    setButtonDisabled(true);
    onItemClick(direction === "left" ? 0 : 1);
  };

  const renderTitle = () => {
    return <h2>{item.description?.toUpperCase()}</h2>;
  };

  const renderChoices = () => {
    if (item.multi && !item.ranked) {
      //Multi-choice question without sorting
      return (
        <div className={styles.column}>
          {item.choices.map((singleItem: string, index: number) => (
            <div
              className={`${styles.button} ${
                selectedData.includes(index) ? styles.noHover : ""
              }`}
              key={singleItem}
              onClick={() => onItemClick(index)}
            >
              <img
                className={styles.selectButton}
                alt=""
                src={
                  selectedData.includes(index)
                    ? buttonSelected
                    : buttonUnselected
                }
              />
              <h4>{renderSingleChoice(singleItem)}</h4>
            </div>
          ))}
        </div>
      );
    }

    if (!item.multi && !item.ranked && item.choices.length > 2) {
      //Single-choice question that is not TINDER-like
      return (
        <div className={styles.column}>
          {item.choices.map((singleItem: string, index: number) => (
            <div
              className={`${styles.button} ${
                selectedData.includes(index) ? styles.noHover : ""
              }`}
              key={singleItem}
              onClick={() => onItemClick(index)}
            >
              <img
                className={styles.selectButton}
                alt=""
                src={
                  selectedData.includes(index)
                    ? buttonSelected
                    : buttonUnselected
                }
              />
              <h4>{renderSingleChoice(singleItem)}</h4>
            </div>
          ))}
        </div>
      );
    }

    if (item.multi && item.ranked) {
      //Multi-choice question WITH sorting
      const onDragEnd = (result: DropResult, _provided: ResponderProvided) => {
        if (!result.destination) {
          return;
        }

        const items = reorder(
          data,
          result.source.index,
          result.destination.index
        );

        setData(items);
        const indexes: number[] = items.map((element) =>
          item.choices.indexOf(element)
        );

        setSelectedData(indexes);
      };

      return (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={item.prop_id}>
            {(provided, _snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className={styles.column}
              >
                {data.map((singleItem: string, index: number) => (
                  <Draggable
                    key={singleItem}
                    draggableId={singleItem}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        key={singleItem}
                        className={`${styles.button} ${
                          snapshot.isDragging ? styles.dragging : null
                        }`}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={provided.draggableProps.style}
                      >
                        <img
                          className={styles.selectButton}
                          alt=""
                          src={buttonUnselected}
                        />
                        <img className={styles.dragImg} alt="" src={dragImg} />
                        <h4>{renderSingleChoice(singleItem)}</h4>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      );
    }

    if (!item.multi && !item.ranked && item.choices.length === 2) {
      //TINDER-like question
      return (
        <div className={styles.tinderRow}>
          <div
            className={styles.button}
            onClick={() => handleButtonClick("left")}
          >
            <SelectButton className={styles.selectButton} />
            <h4>{renderSingleChoice(item.choices[0])}</h4>
          </div>
          <div
            className={styles.button}
            onClick={() => handleButtonClick("right")}
          >
            <SelectButton className={styles.selectButton} />
            <h4>{renderSingleChoice(item.choices[1])}</h4>
          </div>
        </div>
      );
    }

    return null;
  };

  const renderDoneButton = () => {
    if (!item.multi && !item.ranked && item.choices.length === 2) return null; //Tinder-like question

    const onDoneClickHandler = (isEnabledBool: boolean) => {
      if (!isEnabledBool) {
        return;
      }
      onClick(item.prop_id, selectedData);
      setIsShown(false);
    };

    const isEnabled = selectedData.length >= 1 || (item.ranked && item.multi);

    return (
      <div
        className={`${styles.doneContainer} ${
          !isEnabled ? styles.doneDisabled : ""
        }`}
        onClick={() => onDoneClickHandler(isEnabled)}
      >
        <img
          className={`${styles.doneImage} ${
            isEnabled ? styles.doneEnabled : ""
          }`}
          src={isEnabled ? doneEnabled : doneDisabled}
          draggable="false"
          alt="Done button"
        />
      </div>
    );
  };

  if (!isShown) return null;
  if (currentQuestion !== index) return null;

  const Container: React.FC<ContainerProps> = ({ children }) => {
    if (!item.multi && !item.ranked && item.choices.length === 2) {
      return (
        <TinderCard
          onCardLeftScreen={onSwipeCompleted}
          onSwipeRequirementFulfilled={() => console.log("FULFILL")}
          preventSwipe={["up", "down"]}
          className={styles.container}
          ref={cardRef}
        >
          {children}
        </TinderCard>
      );
    }

    return <div className={styles.container}>{children}</div>;
  };

  return (
    <div className={styles.mainContainer}>
      <Container>
        <div className={styles.imageContainer}>
          <img
            className={styles.image}
            src={item.image}
            draggable="false"
            alt=""
          />
        </div>
        <div className={styles.titleContainer}>{renderTitle()}</div>
      </Container>
      {renderChoices()}
      {renderDoneButton()}
    </div>
  );
};

export default Card;
